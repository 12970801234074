import {
    createRouter,
    createWebHistory,
    createWebHashHistory,
} from "vue-router";
import homeView from "../views/homeView.vue";
import { nextTick } from "vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: homeView,
        meta: {
            authRequired: false,
        },
    },
    {
        path: "/login",
        // path: '/:pathMatch(#login)',
        name: "login",
        meta: {
            authRequired: false,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/loginView.vue"),
    },
    {
        path: "/office-home",
        name: "office-home",
        meta: {
            authRequired: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/officeHomeView.vue"
            ),
    },
    {
        path: "/technician-home",
        name: "technician-home",
        meta: {
            authRequired: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/technicianHomeView.vue"
            ),
    },
    {
        path: "/statistics",
        name: "statistics",
        meta: {
            authRequired: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../views/statisticsView.vue"
            ),
    },
];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, _from, next) => {
    const token = localStorage.getItem("token");

    const isUserLoggedIn = token !== null;

    if (!isUserLoggedIn && to.meta.authRequired) {
        return next({ name: "login" });
    } else if ((isUserLoggedIn && to.name === "login") || to.name === "home") {
        return next({ name: "office-home" });
    }

    return next();
});

export default router;
