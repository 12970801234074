<template>
    <general-nav />
    <div class="container">
        <div class="home">
            <div class="logo-container">
                <img class="logo" src="@/assets/logo.png" />
            </div>
            <div class="heading-container">
                <h1 class="welcome-title mt-5">Dobrodošli u raspored!</h1>
            </div>
        </div>
    </div>
</template>

<script>
import GeneralNav from "@/components/navbars/homeNav.vue";
export default {
    name: "HomeView",
    components: {
        GeneralNav,
    },
};
</script>

<style scoped>
.container {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-container {
    display: flex;
    justify-content: center;
}
.logo {
    width: 200px;
}
.welcome-title {
    color: #000;
}
</style>
