<template>
    <div class="router-container">
        <router-view />
    </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    // overflow: auto;
    .btn-primary {
        background-color: #000;
        border: #000;
        color: white !important;
    }
    .btn-primary:hover {
        background-color: #000;
    }
    .btn-primary:active {
        background-color: #000 !important;
    }
    .btn-primary:focus {
        background-color: #000;
        box-shadow: 0 0 0 0.2rem #00000070 !important;
    }
    .form-control:focus {
        box-shadow: 0 0 0 0.2rem #00000070 !important;
        border-color: #00000070;
    }
    .form-check-input:focus {
        box-shadow: 0 0 0 0.2rem #00000070 !important;
        border-color: #147d8d;
    }
    .form-check-input:checked {
        border-color: #147d8d;
        background-color: #147d8d;
    }
    .form-group {
        margin-bottom: 1vw;
    }
}
.router-container {
    height: 100vh;
}
</style>
